import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { widget } from "../../charting_library";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const TVChartContainer = () => {
  const chartContainerRef = useRef();
  const [symbol, setSymbol] = useState("AAPL");
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (!!query.get("symbol")) {
      setSymbol(query.get("symbol"));
    }
  }, []);

  const defaultProps = {
    symbol: "AAPL",
    interval: "5",
    datafeedUrl:
      process.env.REACT_APP_BASE_URL ?? "https://demo_feed.tradingview.com",
    // datafeedUrl: "http://192.168.1.15:3000",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,

    studiesOverrides: {},
  };

  useEffect(() => {
    const widgetOptions = {
      //   symbol: defaultProps.symbol,
      symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        defaultProps.datafeedUrl
      ),
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,

      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_compare",
        "header_symbol_search",
      ],
      enabled_features: ["study_templates"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: "dark",
    };

    const tvWidget = new widget(widgetOptions);

    // tvWidget.onChartReady(() => {
    //   tvWidget.headerReady().then(() => {
    //     const button = tvWidget.createButton();
    //     button.setAttribute("title", "Click to show a notification popup");
    //     button.classList.add("apply-common-tooltip");
    //     button.addEventListener("click", () =>
    //       tvWidget.showNoticeDialog({
    //         title: "Notification",
    //         body: "TradingView Charting Library API works correctly",
    //         callback: () => {
    //           console.log("Noticed!");
    //         },
    //       })
    //     );

    //     button.innerHTML = "Check API";
    //   });
    // });

    return () => {
      tvWidget.remove();
    };
  });

  return <div ref={chartContainerRef} className={"TVChartContainer"} />;
};
